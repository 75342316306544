import { useSelector } from "react-redux";
import useRout from "./Routes";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/login/Login";
import "./styled/style.scss";
import LoadingScreen from "./pages/LoadingScreen/LoadingScreen";
import Sidebar from "./components/Sidebar/Sidebar";

function App() {
  let { data } = useSelector((state) => state.persistTheme.auth);
  const routes = useRout(data && data.token);
  let { dark } = useSelector((state) => state.persistTheme.themeslice);
  const [t, i18n] = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const currentRoute = window.location.pathname;

  console.log("data==>", data);

  return (
    <div>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <BrowserRouter>
          <div style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}>
            <div className={dark ? "app dark" : "app"}>
              <div className="row ">
                {currentRoute === "/login" ? (
                  ""
                ) : currentRoute === "/register" ? (
                  ""
                ) : (
                  <div className="col-xl-2 col-lg-2">
                    <Sidebar />
                  </div>
                )}
                <Fragment>
                  <Routes>
                    {routes.map((route, index) => (
                      <Route
                        key={index}
                        exact
                        path={route.path}
                        element={
                          data && data.token ? (
                            route.element
                          ) : route.path === "login" ? (
                            <Login />
                          ) : (
                            <Login path={route.path} />
                          )
                        }
                      />
                    ))}
                  </Routes>
                </Fragment>
              </div>
            </div>
          </div>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
