import { DialogTitle, Switch, TextField, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import {
  createCompany,
  getAllCompany,
  getSingleCompany,
  resetDataCreate,
  resetDataUpdate,
  resetsingleDataComapny,
  updateCompany,
} from "../../../store/companySlice";

function PopUpCompany({ type, handleClose, data }) {
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  let [inp, setInp] = useState("");

  const [showPassword, setShowPassword] = React.useState(false);
  const [t, i18n] = useTranslation();
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  let dispatch = useDispatch();

  let {
    data: states,
    loadingBTN,
    error,
    dataCreate,
    dataUpdate,
    loadingSingle,
    errorSingle,
    singleData,
  } = useSelector((state) => state.companies);

  useEffect(() => {
    if (dataCreate || dataUpdate) {
      dispatch(getAllCompany({ lang: i18n.language }));
      handleClose();
    }
  }, [dataCreate, dataUpdate]);

  useEffect(() => {
    if (type === "edit") {
      dispatch(getSingleCompany({ id: data.id, lang: i18n.language }));
    }
  }, [type]);

  let getValue = (e) => {
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  let handleSwitchChange = (e, name) => {
    setInp((prev) => {
      return { ...prev, [name]: e.target.checked ? 1 : 0 };
    });
  };

  let onsubmitfn = (e) => {
    e.preventDefault();
    if (type === "add") {
      console.log("addd");
      dispatch(createCompany({ data: inp, lang: i18n.language }));
    }
    if (type === "edit") {
      console.log("ediit");
      let newdata = {
        ...inp,
        _method: "PUT",
      };
      dispatch(
        updateCompany({
          id: singleData.id,
          data: newdata,
          lang: i18n.language,
        })
      );
    }
  };

  console.log("inpp<...>", inp);
  console.log("singleData<...>", singleData);
  return (
    <Fragment>
      <DialogTitle sx={{ p: 3 }}>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            borderBottom: "1px solid lightgray",
            paddingBottom: "2px",
            marginBottom: "30px",
          }}
        >
          {type === "edit" ? t("Edit Comapny") : t("Add Comapny")}
        </Typography>

        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form class="row" onSubmit={(e) => onsubmitfn(e)}>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <TextField
                  id={"text"}
                  type={"text"}
                  label={t("name")}
                  name={"name"}
                  defaultValue={
                    type === "edit" ? singleData && singleData.name : ""
                  }
                  required
                  onChange={(e) => getValue(e)}
                  variant="standard"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="col-md-6  mb-3">
              <div className="form-group">
                <TextField
                  id={"email"}
                  type={"email"}
                  required
                  label={t("Email")}
                  defaultValue={
                    type === "edit" ? singleData && singleData.email : ""
                  }
                  name={"email"}
                  onChange={(e) => getValue(e)}
                  variant="standard"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="col-md-6 mb-3">
              <div className="form-group">
                <TextField
                  id={"tax_no"}
                  type={"number"}
                  label={t("Tax No")}
                  name={"tax_no"}
                  defaultValue={
                    type === "edit" ? singleData && singleData.tax_no : ""
                  }
                  required
                  onChange={(e) => getValue(e)}
                  variant="standard"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="col-md-6 mb-3">
              <div className="form-group">
                <TextField
                  id={"crn"}
                  type={"number"}
                  label={t("commercial registration no")}
                  name={"commercial_registration_no"}
                  required
                  defaultValue={
                    type === "edit"
                      ? singleData && singleData.commercial_registration_no
                      : ""
                  }
                  onChange={(e) => getValue(e)}
                  variant="standard"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="col-md-6  mb-3">
              <div className="form-group">
                <p>
                  {t("Auto Renew")}:<sup className="redstar">*</sup>
                </p>
                <Switch
                  {...label}
                  defaultChecked={
                    type === "edit" && singleData && singleData.auto_renew == 1
                      ? true
                      : false
                  }
                  onChange={(event) => handleSwitchChange(event, "auto_renew")}
                />
              </div>
            </div>

            <div className="col-md-6  mb-3">
              <div className="form-group">
                <p>
                  {t("Active")}:<sup className="redstar">*</sup>
                </p>
                <Switch
                  {...label}
                  defaultChecked={
                    type === "edit" && singleData && singleData.active == 1
                      ? true
                      : false
                  }
                  onChange={(event) => handleSwitchChange(event, "active")}
                />
              </div>
            </div>

            <div className="btn-lest mt-3">
              {loadingBTN ? (
                <button type="text" disabled className="btn btn-primary-rgba ">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" />
                  {type === "edit" ? t("Edit") : t("Craete")}
                </button>
              )}

              <span onClick={handleClose} className="btn btn-danger-rgba">
                <DoNotDisturbAltIcon color="#fff" /> {t("Cancel")}
              </span>
            </div>
          </form>
        )}
      </DialogTitle>
    </Fragment>
  );
}

export default PopUpCompany;
