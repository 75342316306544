import React, { useEffect, useState } from "react";
import "./RolePermEditAdd.scss";
import Navbar from "../../../components/Navbar/Navbar";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useParams } from "react-router-dom";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import NewRoleCheckBox from "../../../components/NewRoleCheckBox/NewRoleCheckBox";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import {
  createRoles,
  getAllPermissions,
  getSingleRole,
  updateRoles,
} from "../../../store/roleSlice";

function RolePermEditAdd() {
  const [t, i18n] = useTranslation();

  let navigate = useNavigate();
  let goBackFn = () => {
    navigate(-1);
  };
  let { id, name: title } = useParams();

  const [selectAll, setSelectAll] = useState(false); // Manage Select All state

  let dispatch = useDispatch();
  useEffect(() => {
    if (title !== "add") {
      dispatch(getSingleRole(id));
    }
    dispatch(getAllPermissions());
  }, [title]);

  let {
    dataSingle: roles,
    loading,
    loadingSingle,
    loadingBTN,
    error,
    perme,
  } = useSelector((state) => state.roles);
  console.log("permepermeperme", perme);
  console.log("rolesroles", roles);
  const [golobalPermissions, setGlobalPermissions] = useState("");

  let [checkValue, setCheckValue] = useState([]);
  useEffect(() => {
    if (title === "edit") {
      let getids = roles && roles.permissions.map((e) => e.id);
      setCheckValue(getids);
    }
  }, [title, roles, perme]);

  useEffect(() => {
    if (title === "add") {
      setGlobalPermissions(
        Object.keys(perme).map((name) => {
          let permation = perme[name].map((e) => ({
            name: e.name,
            id: e.id,
            state: false,
          }));
          return {
            name: name,
            permation,
          };
        })
      );
    } else {
      setGlobalPermissions(
        Object.keys(perme).map((name) => {
          let permation = perme[name].map((e) => {
            return {
              name: e.name,
              id: e.id,
              state: roles && roles.permissions.some((id) => id.id === e.id),
            };
          });
          return {
            name: name,
            permation,
          };
        })
      );
    }
  }, [title, roles, perme]);

  let handleCheack = (e, type, state) => {
    if (type === "single") {
      let Value = [...checkValue];
      if (Value.includes(e)) {
        let filter = Value.filter((id) => id !== e);
        Value = filter;
      } else {
        Value = [...Value, e];
      }
      setCheckValue(Value);
    } else {
      let Value = [...checkValue];
      e.map((nn) => {
        if (state === "add") {
          if (Value.includes(nn)) {
          } else {
            Value = [...Value, nn];
          }
          setCheckValue(Value);
        } else {
          if (Value.includes(nn)) {
            let filter = Value.filter((id) => id !== nn);
            Value = filter;
          }
          setCheckValue(Value);
        }
      });
    }
  };

  let recallFn = (e) => {
    dispatch(getSingleRole(id));
  };

  let [NameRole, setNameRole] = useState("");
  let SendRole = async (e) => {
    e.preventDefault();
    if (title === "add") {
      let newdata = {
        name: NameRole,
        permissions: checkValue,
      };
      console.log("newdata==>", newdata);
      try {
        dispatch(createRoles({ data: newdata, lang: i18n.language }));
        goBackFn();
      } catch (error) {
        console.log("error");
      }
    }
    if (title === "edit") {
      let newdata = {};
      if (NameRole) {
        newdata = {
          name: NameRole,
          permissions: checkValue,
          _method: "PUT",
        };
      } else {
        newdata = {
          name: roles.name,
          permissions: checkValue,
          _method: "PUT",
        };
      }
      console.log("newdata==>", newdata);
      try {
        dispatch(
          updateRoles({
            id: roles.id,
            data: newdata,
            lang: i18n.language,
          })
        );
        goBackFn();
      } catch (error) {
        console.log("errror", error);
      }
    }
  };

  console.log("checkValue==>", checkValue);
  console.log("roles==>", roles);
  console.log("golobalPermissions==>", golobalPermissions);

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <div className="main-header  add-header">
          {title === "add" ? (
            <h4>{t("Create Role")}</h4>
          ) : (
            <h4>{t("Edit Role")}</h4>
          )}

          <div className="btn-list">
            <button onClick={(e) => goBackFn()} className="btn">
              <KeyboardBackspaceIcon /> {t("Back")}
            </button>
          </div>
        </div>
        {error ? (
          <ErrorCompo />
        ) : loading || loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form className="row role-blog" onSubmit={(e) => SendRole(e)}>
            <div className="col-md-12">
              <div className="form-group mb-5">
                <label className="text-dark" htmlFor="name">
                  {t("Role Name")}: <sup className="text-danger">*</sup>
                </label>
                <input
                  id="name"
                  required
                  type="text"
                  name="name"
                  onChange={(e) => {
                    setNameRole(e.target.value);
                  }}
                  defaultValue={roles ? roles.name : ""}
                  placeholder="Please Enter Role Name"
                  className="form-control"
                />
              </div>
            </div>

            <p className="text-dark col-md-12">
              <b>{t("Assign Permissions to role")}:</b>
            </p>

            {title === "add" ? (
              <div className="box-roles row">
                {golobalPermissions &&
                  golobalPermissions.map((e) => (
                    <NewRoleCheckBox data={e} handleCheack={handleCheack} />
                  ))}
              </div>
            ) : golobalPermissions && roles ? (
              <div className="box-roles row">
                {golobalPermissions?.length > 0 &&
                  golobalPermissions?.map((e, index) => (
                    <NewRoleCheckBox
                      data={e}
                      key={index}
                      handleCheack={handleCheack}
                    />
                  ))}
              </div>
            ) : (
              <div className="box-wrong">
                <p>{t("Somthing Wrong")}</p>
                <button className="btn main-btn" onClick={(e) => recallFn(e)}>
                  {t("Refresh")}
                </button>
              </div>
            )}

            <div className="btn-lest">
              <button type="reset" className="btn btn-danger-rgba">
                <DoNotDisturbAltIcon color="#fff" /> {t("Reset")}
              </button>

              {loadingBTN ? (
                <button type="text" disabled className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" />{" "}
                  {title === "edit" ? `${t("Update")}` : `${t("Create")}`}
                </button>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default RolePermEditAdd;
