import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Actions from "../../../components/Actions/Actions";
import { useDispatch } from "react-redux";
import Navbar from "../../../components/Navbar/Navbar";
import HeaderPage from "../../../components/HeaderPage/HeaderPage";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import Datatable from "../../../components/datatable/Datatable";
import { useSelector } from "react-redux";
import { getAllLanguspeak } from "../../../store/LanguageSpeakSlice";

function LanguSpeak() {
  const [t, i18n] = useTranslation();
  const label = { inputProps: { "aria-label": "Size switch demo" } };

  let data = [
    {
      id: 1,
      name: "English",
    },
    {
      id: 2,
      name: "Arabic",
    },
    {
      id: 3,
      name: "France",
    },
  ];

  const userColumns = [
    { field: "id", headerName: t("ID"), width: 80 },
    {
      field: "name",
      headerName: t("Name"),
      width: 750,
    },
    {
      field: "Action",
      headerName: t("Action"),
      sortable: false,
      filter: false,
      width: 200,
      renderCell: (params) => {
        return (
          <div>
            <Actions filter="languspeak" params={params} />
          </div>
        );
      },
    },
  ];

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  // Function to handle selection change
  const handleSelectionChange = (selectionModel) => {
    // Store the selected row IDs in state
    setSelectedRowIds(selectionModel);
    // Log the selected row IDs to the console
    console.log("Selected Row IDs:", selectionModel);
  };

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllLanguspeak({ lang: i18n.language }));
  }, []);

  let {
    data: languspeak,
    loading,
    errorAll,
  } = useSelector((state) => state.languspeak);

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <HeaderPage
          data={languspeak}
          selectedRowIds={selectedRowIds}
          title="Language Speak"
          filter="languspeak"
        />
        <div className="table">
          {errorAll ? (
            <ErrorCompo />
          ) : loading ? (
            <div className="loading">
              <CircularIndeterminate />
            </div>
          ) : (
            <Datatable
              userColumns={userColumns}
              userRows={languspeak && languspeak}
              onSelectionModelChange={handleSelectionChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default LanguSpeak;
