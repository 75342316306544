import React from "react";
import ModalDelete from "../ModalDelete/ModalDelete";
import ModalMulti from "../ModalMulti/ModalMulti";
import ExportFunctions from "../ExportFunctions/ExportFunctions";
import { useTranslation } from "react-i18next";

function HeaderPage({ data, selectedRowIds, title, filter }) {
  const [t, i18n] = useTranslation();
  return (
    <div className="main-header">
      <h3>{t(title)}</h3>
      <div className="btn-list">
        <ExportFunctions data={data} title="admin" />
        <button className="btn">
          <ModalMulti
            data={data}
            filter={filter}
            type="add"
            title={"Add New"}
          />
        </button>
        <button className="btn btn-delete">
          <ModalDelete
            filter={filter}
            params={data}
            type="all"
            selectedRowIds={selectedRowIds}
          />
        </button>
      </div>
    </div>
  );
}

export default HeaderPage;
