import React from "react";
import "./ModalDelete.scss";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import DeletePopUpUser from "../../pages/Folder-Users/DeletePopUpUser/DeletePopUpUser";
import { Dialog } from "@mui/material";
import DeletePopUpCompnay from "../../pages/Folder-Comapny/DeletePopUpCompnay/DeletePopUpCompnay";
import DeletePopUpCategory from "../../pages/Folder-Category/DeletePopUpCategory/DeletePopUpCategory";
import DeletePopUpChronicD from "../../pages/FolderChronicD/DeletePopUpChronicD/DeletePopUpChronicD";
import DelelePopUpAccessM from "../../pages/Folder-AccessMethod/DelelePopUpAccessM/DelelePopUpAccessM";
import DeletePupUpLanguSpeak from "../../pages/Folder-LanguageSpeak/DeletePupUpLanguSpeak/DeletePupUpLanguSpeak";
import DeletePopUpSubscription from "../../pages/Folder-Subscription/DeletePopUpSubscription/DeletePopUpSubscription";
import PopUpDeleteCategory from "../../pages/Folder-role/DeletePopUpRole/DeletePopUpRole";

function ModalDelete({ params: data, type, selectedRowIds, filter }) {
  const [t, i18n] = useTranslation();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      <div onClick={handleOpen} className="d-flex gap-2 align-items-center">
        {<DeleteIcon />}
        {type === "all" ? t("Delete Selected") : ""}
      </div>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        {filter === "users" ? (
          <DeletePopUpUser
            type={type}
            handleClose={handleClose}
            selectedRowIds={selectedRowIds}
            data={data}
          />
        ) : filter === "Companies" ? (
          <DeletePopUpCompnay
            type={type}
            handleClose={handleClose}
            selectedRowIds={selectedRowIds}
            data={data}
          />
        ) : filter === "Categories" ? (
          <DeletePopUpCategory
            type={type}
            handleClose={handleClose}
            selectedRowIds={selectedRowIds}
            data={data}
          />
        ) : filter === "ChronicD" ? (
          <DeletePopUpChronicD
            type={type}
            handleClose={handleClose}
            selectedRowIds={selectedRowIds}
            data={data}
          />
        ) : filter === "accessm" ? (
          <DelelePopUpAccessM
            type={type}
            handleClose={handleClose}
            selectedRowIds={selectedRowIds}
            data={data}
          />
        ) : filter === "languspeak" ? (
          <DeletePupUpLanguSpeak
            type={type}
            handleClose={handleClose}
            selectedRowIds={selectedRowIds}
            data={data}
          />
        ) : filter === "subscription" ? (
          <DeletePopUpSubscription
            type={type}
            handleClose={handleClose}
            selectedRowIds={selectedRowIds}
            data={data}
          />
        ) : filter === "Role" ? (
          <PopUpDeleteCategory
            type={type}
            handleClose={handleClose}
            selectedRowIds={selectedRowIds}
            data={data}
          />
        ) : (
          ""
        )}
      </Dialog>
    </div>
  );
}

export default ModalDelete;
