import { DialogTitle, TextField, Typography } from "@mui/material";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React, { Fragment, useEffect, useState } from "react";
import CheckMultiLangu from "../../../components/CheckMultiLangu/CheckMultiLangu";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  createLanguspeak,
  getAllLanguspeak,
  getSingleLanguSpeak,
  updateLanguSpeak,
} from "../../../store/LanguageSpeakSlice";
import { useSelector } from "react-redux";

function PopUpLanguSpeak({ type, handleClose, data }) {
  let [inp, setInp] = useState("");
  const [t, i18n] = useTranslation();

  let dispatch = useDispatch();

  let {
    data: languspeak,
    loadingBTN,
    error,
    dataCreate,
    dataUpdate,
    loadingSingle,
    errorSingle,
    singleData,
  } = useSelector((state) => state.languspeak);

  useEffect(() => {
    if (dataCreate || dataUpdate) {
      dispatch(getAllLanguspeak({ lang: i18n.language }));
      handleClose();
    }
  }, [dataCreate, dataUpdate]);

  useEffect(() => {
    if (type === "edit") {
      dispatch(getSingleLanguSpeak({ id: data.id, lang: i18n.language }));
    }
  }, [type]);

  let getValue = (e) => {
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  let handleSwitchChange = (e, name) => {
    setInp((prev) => {
      return { ...prev, [name]: e.target.checked ? 1 : 0 };
    });
  };

  let onsubmitfn = (e) => {
    e.preventDefault();
    if (type === "add") {
      console.log("addd");
      dispatch(createLanguspeak({ data: inp, lang: i18n.language }));
    }
    if (type === "edit") {
      console.log("ediit");
      let newdata = {
        ...inp,
        _method: "PUT",
      };
      dispatch(
        updateLanguSpeak({
          id: singleData.item.id,
          data: newdata,
          lang: i18n.language,
        })
      );
    }
  };

  // Step 1: Create state to store selected languages
  const [selectedLanguages, setSelectedLanguages] = useState(["en"]);

  const toggleLanguage = (lang) => {
    if (selectedLanguages.includes(lang)) {
      // If the language is already selected, remove it
      setSelectedLanguages(selectedLanguages.filter((l) => l !== lang));
    } else {
      // If the language is not selected, add it
      setSelectedLanguages([...selectedLanguages, lang]);
    }
  };

  useEffect(() => {
    if (type === "edit" && singleData) {
      // Extract translations and set default values
      const translations = singleData.item.translations;
      const defaultValues = translations.reduce((acc, translation) => {
        acc[`translations[${translation.locale}][name]`] = translation.name;
        return acc;
      }, {});

      // Ensure selectedLanguages includes all available locales from translations
      const allLocales = translations.map((translation) => translation.locale);
      setSelectedLanguages([...new Set([...selectedLanguages, ...allLocales])]); // Remove duplicates and add new locales

      setInp(defaultValues);
    }
  }, [type, singleData, singleData?.item?.id]);

  console.log("inpp<...>", inp);
  return (
    <div>
      <DialogTitle sx={{ p: 3 }}>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            borderBottom: "1px solid lightgray",
            paddingBottom: "2px",
            marginBottom: "30px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {type === "edit" ? "Edit Langauge Speak" : "Add Langauge Speak"}

          <CheckMultiLangu
            toggleLanguage={toggleLanguage}
            selectedLanguages={selectedLanguages}
          />
        </Typography>

        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form class="row" onSubmit={(e) => onsubmitfn(e)}>
            {selectedLanguages.map(
              (lang) =>
                selectedLanguages.includes(lang) && (
                  <div className="col-md-12 mb-3">
                    <div className="form-group">
                      <TextField
                        id={"text"}
                        type={"text"}
                        label={t("name") + ` (${lang})`}
                        name={`translations[${lang}][name]`}
                        required
                        onChange={(e) => getValue(e)}
                        variant="standard"
                        style={{ width: "100%" }}
                        value={inp[`translations[${lang}][name]`] || ""}
                      />
                    </div>
                  </div>
                )
            )}

            <div className="btn-lest mt-3">
              {loadingBTN ? (
                <button type="text" disabled className="btn btn-primary-rgba ">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" />
                  {type === "edit" ? t("Edit") : t("Craete")}
                </button>
              )}

              <span onClick={handleClose} className="btn btn-danger-rgba">
                <DoNotDisturbAltIcon color="#fff" /> {t("Cancel")}
              </span>
            </div>
          </form>
        )}
      </DialogTitle>
    </div>
  );
}

export default PopUpLanguSpeak;
