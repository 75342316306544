import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { notifyError, notifysuccess } from "../Notification";
import { BASE_URL } from "../apiConfig";
import Cookies from "js-cookie";
import secondsToDays from "../utils/secondsToDays";

export let regidata = createAsyncThunk(
  "auth/rigdata",
  async (arg, ThunkAPI) => {
    let { rejectWithValue } = ThunkAPI;
    try {
      let res = await axios.post(`${BASE_URL}/register`, arg, {
        headers: { "Content-Type": "application/json" },
      });
      return res.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let logdata = createAsyncThunk("auth/logdata", async (arg, ThunkAPI) => {
  let { rejectWithValue } = ThunkAPI;

  // const csrfToken = document
  //   .querySelector('meta[name="csrf-token"]')
  //   .getAttribute("content");

  // console.log("csrfTokencsrfToken", csrfToken);
  try {
    let res = await axios.post(`${BASE_URL}/admin-panal/auth/login`, arg, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("reseeee", res);
    return res.data.result;
  } catch (error) {
    return rejectWithValue(
      error.response ? error.response.data : error.message
    );
  }
});

export let Logout = createAsyncThunk("auth/logout", async (arg, ThunkAPI) => {
  let { rejectWithValue, getState } = ThunkAPI;
  const token = getState().persistTheme.auth.data.token; // Access the token from the Redux store.
  try {
    let res = await axios.get(`${BASE_URL}/logout`, arg, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
      },
    });
    Cookies.set("esmileToken", res?.data?.token, {
      expires: secondsToDays(res.data.expireInSeconds),
    });
    return res.data;
  } catch (error) {
    return rejectWithValue(
      error.response ? error.response.data : error.message
    );
  }
});

export let updatedata = createAsyncThunk(
  "auth/updatedata",
  async (arg, thunkAPI) => {
    let { rejectWithValue, getState } = thunkAPI;
    let { id } = getState().authslice.data.data;
    try {
      let res = await axios.put(
        `${BASE_URL}/updateUser/${id}`,
        JSON.stringify(arg),
        { headers: { "Content-Type": "application/json" } }
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let deleteDate = createAsyncThunk(
  "auth/deltedata",
  async (arg, thunkAPI) => {
    let { rejectWithValue, getState } = thunkAPI;
    let { id } = getState().authslice.data.data;
    try {
      let res = await axios.delete(`${BASE_URL}/deleteUser/${id}`);
      return res.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let ChangeStatus = createAsyncThunk(
  "ChangeStatus/ChangeStatus",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token; // Access the token from the Redux store.
    try {
      let data = await axios.post(`${BASE_URL}/fixed-attributes`, arg, {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
        },
      });
      return data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

let authslice = createSlice({
  name: "auth",
  initialState: {
    data: false,
    loading: false,
    error: false,
    logged: false,
  },
  reducers: {
    showsliderfn: (state, action) => {
      state.showslider = !state.showslider;
    },
    islogged: (state, action) => {
      state.logged = action.payload;
    },
  },
  extraReducers: {
    //ChangeStatus

    [ChangeStatus.pending]: (state, action) => {},
    [ChangeStatus.fulfilled]: (state, action) => {
      notifysuccess(action.payload.message);
    },
    [ChangeStatus.rejected]: (state, action) => {
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },
    //regidata

    [regidata.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
    },
    [regidata.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = false;
    },
    [regidata.rejected]: (state, action) => {
      state.loading = false;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //logdata
    [logdata.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
    },
    [logdata.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [logdata.rejected]: (state, action) => {
      state.loading = false;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //Logout
    [Logout.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
      state.data = null;
    },
    [Logout.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = null;
    },
    [Logout.rejected]: (state, action) => {
      state.loading = false;
    },

    //updatedata
    [updatedata.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
    },
    [updatedata.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [updatedata.rejected]: (state, action) => {
      state.loading = false;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //deletedata
    [deleteDate.fulfilled]: (state, action) => {
      state.data = null;
    },
  },
});

export default authslice.reducer;

export let { showsliderfn, islogged } = authslice.actions;
