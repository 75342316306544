import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function NewRoleCheckBox({ data, handleCheack }) {
  const [t, i18n] = useTranslation();

  let [TogglAll, setToggleAll] = useState("");

  useEffect(() => {
    setToggleAll(data && data.permation.every((e) => e.state === true));
  }, [data]);

  let [newData, setNewData] = useState(data.permation);
  console.log("data==>", newData);

  let handleSelectAll = (e, type) => {
    let mm = !TogglAll;
    let allIds = data.permation.map((e) => e.id);
    const updatedPermissions = newData.map((permission) => ({
      ...permission,
      state: !TogglAll,
    }));
    setNewData(updatedPermissions);
    setToggleAll(mm);

    handleCheack(allIds, "all", type ? "remove" : "add");
  };
  let handleSingleChek = (e) => {
    const updatedPermissions = newData.map((permission) => {
      if (permission.id === e) {
        // Toggle the state for the clicked permission
        return { ...permission, state: !permission.state };
      }
      return permission;
    });

    setNewData(updatedPermissions);
  };

  return (
    <Fragment>
      {/* <div className="col-lg-4 col-sm-6 mb-4"> */}
      <div className="col-lg-12">
        <div className="box">
          <div className="header">
            <h6>{data && data.name}</h6>
            <label>
              <Fragment>
                <input
                  type="checkbox"
                  defaultChecked={
                    data && data.permation.every((e) => e.state === true)
                  }
                  onChange={(e) => {
                    handleSelectAll(e, TogglAll);
                  }}
                />
              </Fragment>
              {t("Select All")}
            </label>
          </div>
          <div className="row chackes">
            {newData &&
              newData.map((e) => (
                <Fragment>
                  <div key={e.id} className="col-sm-4">
                    <label className="mb-3">
                      <input
                        type="checkbox"
                        name={e.id}
                        checked={e.state}
                        onChange={() => {
                          handleSingleChek(e.id);
                          handleCheack(e.id, "single");
                        }}
                      />

                      {e.name.split(".").pop()}
                    </label>
                  </div>
                </Fragment>
              ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default NewRoleCheckBox;
