import {
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import ResponsiveDateRangePickers from "../../../components/DateRange/DateRange";
import {
  createSubsec,
  getAllSubsec,
  getSingleSubsec,
  updateSubsec,
} from "../../../store/subscriptionSlice";
import { notifyError } from "../../../Notification";
import { format } from "date-fns";
import {
  getacompaniesList,
  getadminsRoles,
  getasubscriptionList,
  getSupsecrptionMethods,
} from "../../../store/commonSlice";

function PopUpSubscription({ type, handleClose, data }) {
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  let [inp, setInp] = useState("");
  const [t, i18n] = useTranslation();
  let [dateSelected, setDateSelected] = useState("");

  let dispatch = useDispatch();

  let {
    data: states,
    loadingBTN,
    error,
    dataCreate,
    dataUpdate,
    loadingSingle,

    errorSingle,
    singleData,
  } = useSelector((state) => state.subscription);

  let {
    companies,
    loadingcompanies,
    error: errorCommon,
    loaidngMethod,
    subscriptionMEthod,
    subscription,
    loadingSub,
  } = useSelector((state) => state.commonlists);

  useEffect(() => {
    if (dataCreate || dataUpdate) {
      dispatch(getAllSubsec({ lang: i18n.language }));
      handleClose();
    }
  }, [dataCreate, dataUpdate]);

  useEffect(() => {
    if (type === "edit") {
      dispatch(getSingleSubsec({ id: data.id, lang: i18n.language }));
    }
    dispatch(getacompaniesList({ lang: i18n.language }));
    dispatch(getasubscriptionList({ lang: i18n.language }));
    dispatch(getSupsecrptionMethods({ lang: i18n.language }));
  }, [type]);

  console.log("subscriptionMEthodsubscriptionMEthod", subscriptionMEthod);

  const [val, setVal] = useState("");
  let getValue = (e) => {
    setVal(e.target.value);
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  let handleSwitchChange = (e, name) => {
    setInp((prev) => {
      return { ...prev, [name]: e.target.checked ? 1 : 0 };
    });
  };

  let onsubmitfn = (e) => {
    e.preventDefault();
    if (type === "add") {
      let newDate;
      if (dateSelected) {
        newDate = {
          ...inp,
          start_date: format(dateSelected[0]?.startDate, "yyyy-MM-dd"),
          end_date: format(dateSelected[0]?.endDate, "yyyy-MM-dd"),
          "user_subscription_transactions[0][payment_method]": "CASH",
        };
      }
      console.log("newDate==>", newDate);
      if (dateSelected === "") {
        notifyError("Chosse Date please");
      }
      if (dateSelected[0]?.startDate === undefined) {
        notifyError("Chosse Start Date please");
      }
      if (dateSelected[0]?.endDate === undefined) {
        notifyError("Chosse End Date please");
      }

      if (dateSelected) {
        dispatch(createSubsec({ data: newDate, lang: i18n.language }));
      }
    }
    if (type === "edit") {
      console.log("ediit");
      let newdata;
      if (dateSelected) {
        newdata = {
          ...inp,
          start_date: format(dateSelected[0]?.startDate, "yyyy-MM-dd"),
          end_date: format(dateSelected[0]?.endDate, "yyyy-MM-dd"),
          _method: "PUT",
        };
      } else {
        newdata = {
          ...inp,
          _method: "PUT",
        };
      }

      dispatch(
        updateSubsec({
          id: singleData.id,
          data: newdata,
          lang: i18n.language,
        })
      );
    }
  };

  let payment_method = [
    { name: "CASH  ", id: 1 },
    { name: "TRANSFER ", id: 2 },
    { name: "CARD", id: 3 },
  ];

  console.log("inpp<...>", inp);
  console.log("singleData<...>", singleData);
  console.log("companies<...>", companies);
  console.log("subscription<...>", subscription);
  return (
    <Fragment>
      <DialogTitle sx={{ p: 3 }}>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            borderBottom: "1px solid lightgray",
            paddingBottom: "2px",
            marginBottom: "30px",
          }}
        >
          {type === "edit" ? t("Edit Subscription") : t("Add Subscription")}
        </Typography>

        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form class="row" onSubmit={(e) => onsubmitfn(e)}>
            <div className="col-md-12 mb-3">
              <FormControl fullWidth>
                <InputLabel id="company">{t("Company")}</InputLabel>
                <Select
                  labelId="Company"
                  name="company_id"
                  id="company"
                  defaultValue={
                    type === "edit" && singleData ? singleData.company.id : ""
                  }
                  required
                  label={"Company"}
                  onChange={(e) => getValue(e)}
                >
                  {loadingcompanies ? (
                    <MenuItem disabled>loading</MenuItem>
                  ) : (
                    companies &&
                    companies.map((e) => (
                      <MenuItem key={e.id} value={e.id}>
                        {e.name}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </div>

            <div className="col-lg-12">
              <div className="form-group">
                <InputLabel id="Date">{t("Date")}</InputLabel>
                <ResponsiveDateRangePickers
                  setDateSelected={setDateSelected}
                  singleData={singleData && singleData}
                  type={type}
                />
              </div>
            </div>

            <div className="col-md-12 mb-3">
              <FormControl fullWidth>
                <InputLabel id="Status">{t("Status")}</InputLabel>
                <Select
                  labelId="Status"
                  name="user_subscription_transactions[0][status]"
                  id="Status"
                  defaultValue={
                    type === "edit" && singleData
                      ? singleData.user_subscription_transactions[0].status
                      : ""
                  }
                  required
                  label={"Status"}
                  onChange={(e) => getValue(e)}
                >
                  {loadingSub ? (
                    <MenuItem disabled>loading</MenuItem>
                  ) : (
                    subscription &&
                    subscription.map((e) => (
                      <MenuItem key={e.id} value={e.id}>
                        {e.name}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </div>

            <div className="col-md-12 mb-3">
              <FormControl fullWidth isRequred>
                <InputLabel id="Payment method">
                  {t("Payment method")}
                </InputLabel>
                <Select
                  labelId="Payment method"
                  name="user_subscription_transactions[0][payment_method]"
                  id="payment method"
                  defaultValue={
                    type === "edit" && singleData
                      ? singleData?.user_subscription_transactions[0]
                          ?.payment_method
                      : ""
                  }
                  required
                  isRequred
                  label={"Payment method"}
                  onChange={(e) => getValue(e)}
                >
                  {loaidngMethod ? (
                    <MenuItem disabled>loading</MenuItem>
                  ) : (
                    subscriptionMEthod &&
                    subscriptionMEthod.map((e) => (
                      <MenuItem key={e.id} value={e.id}>
                        {e.name}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </div>

            <div className="col-md-12 mb-3">
              <div className="form-group">
                <TextField
                  id={"Cost"}
                  label={t("Cost")}
                  name={"user_subscription_transactions[0][cost]"}
                  required
                  type="number"
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 6);
                  }}
                  min={0}
                  onChange={(e) => getValue(e)}
                  defaultValue={
                    type === "edit" && singleData
                      ? singleData.user_subscription_transactions[0].cost
                      : ""
                  }
                  variant="standard"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="col-md-4  mb-3">
              <div className="form-group">
                <p>
                  {t("Renew")}:<sup className="redstar">*</sup>
                </p>
                <Switch
                  {...label}
                  required
                  defaultChecked={
                    type === "edit" && singleData && singleData.renew == 1
                      ? true
                      : false
                  }
                  onChange={(event) => handleSwitchChange(event, "renew")}
                />
              </div>
            </div>

            <div className="col-md-4  mb-3">
              <div className="form-group">
                <p>
                  {t("Free Trial")}:<sup className="redstar">*</sup>
                </p>
                <Switch
                  required
                  {...label}
                  defaultChecked={
                    type === "edit" && singleData && singleData.free_trial == 1
                      ? true
                      : false
                  }
                  onChange={(event) => handleSwitchChange(event, "free_trial")}
                />
              </div>
            </div>

            <div className="col-md-4  mb-3">
              <div className="form-group">
                <p>
                  {t("Active")}:<sup className="redstar">*</sup>
                </p>
                <Switch
                  required
                  {...label}
                  defaultChecked={
                    type === "edit" && singleData && singleData.active == 1
                      ? true
                      : false
                  }
                  onChange={(event) => handleSwitchChange(event, "active")}
                />
              </div>
            </div>

            <div className="btn-lest mt-3">
              {loadingBTN ? (
                <button type="text" disabled className="btn btn-primary-rgba ">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" />
                  {type === "edit" ? t("Edit") : t("Craete")}
                </button>
              )}

              <span onClick={handleClose} className="btn btn-danger-rgba">
                <DoNotDisturbAltIcon color="#fff" /> {t("Cancel")}
              </span>
            </div>
          </form>
        )}
      </DialogTitle>
    </Fragment>
  );
}

export default PopUpSubscription;
