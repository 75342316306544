import axios from "axios";
import { BASE_URL } from "../apiConfig"; // Adjust the path based on your project structure
import { notifyError, notifysuccess } from "../Notification";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export let getAllRoles = createAsyncThunk(
  "roles/getAllRoles",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      let data = await axios.get(`${BASE_URL}/admin-panal/roles`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
        },
      });
      console.log("datadata=>", data);
      return data.data.result.roles;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let createRoles = createAsyncThunk(
  "roles/createRoles",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      let data = await axios.post(`${BASE_URL}/admin-panal/roles`, arg.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
        },
      });
      return data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let updateRoles = createAsyncThunk(
  "roles/updateRoles",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      let data = await axios.post(
        `${BASE_URL}/admin-panal/roles/${arg.id}`,
        arg.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
          },
        }
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let getAllPermissions = createAsyncThunk(
  "roles/getAllPermissions",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      let data = await axios.get(`${BASE_URL}/core/common/list/permissions`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
        },
      });
      console.log("datata-2", data);
      return data.data.result;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let getSingleRole = createAsyncThunk(
  "roles/getSingleRole",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;

    try {
      let data = await axios.get(`${BASE_URL}/admin-panal/roles/${arg}`, {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
        },
      });

      console.log("dtatap0o120", data);
      return data.data.result.item;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const deleteRole = createAsyncThunk(
  "roles/deleteRole",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const idList = Array.isArray(arg.id) ? arg.id : [arg.id]; // Ensure id is an array
      const promises = idList.map((id) =>
        axios.post(`${BASE_URL}/admin-panal/roles/${id}`, arg.data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        })
      );

      // Wait for all deletion requests to complete
      const responses = await Promise.all(promises); // Wait for all deletion requests to complete

      return { idList: idList, responses: responses }; // Return the list of deleted IDs
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

let roleSlice = createSlice({
  name: "roles",
  initialState: {
    data: false,
    dataSingle: false,
    loadingSingle: false,
    perme: false,
    loading: false,
    datacreate: false,
    loadingPermP: false,
    errorPermP: false,
    dataPermP: false,
    error: false,
    errorDelete: false,
    loadingBTN: false,
    dataupdate: false,
  },
  reducers: {
    showsliderfn: (state, action) => {
      state.showslider = !state.showslider;
    },
    islogged: (state, action) => {
      state.logged = action.payload;
    },
    resetsingleDataRole: (state, action) => {
      state.singleData = action.payload;
    },
    resetDataCreate: (state, action) => {
      state.dataCreate = action.payload;
    },
    resetDataUpdate: (state, action) => {
      state.dataUpdate = action.payload;
    },
  },
  extraReducers: {
    //createRoles

    [createRoles.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [createRoles.fulfilled]: (state, action) => {
      state.datacreate = action.payload.data;
      state.loadingBTN = false;
      state.error = false;
      notifysuccess(action.payload.message);
    },
    [createRoles.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //updateRoles

    [updateRoles.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [updateRoles.fulfilled]: (state, action) => {
      state.dataupdate = action.payload;
      state.loadingBTN = false;
      state.error = false;
      notifysuccess(action.payload.message);
    },
    [updateRoles.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //deleteRole

    [deleteRole.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [deleteRole.fulfilled]: (state, action) => {
      state.data = state.data.filter(
        (e) => !action.payload.idList.includes(e.id)
      );
      state.loadingBTN = false;
      state.error = false;
      notifysuccess(action.payload.responses[0].data.message);
    },
    [deleteRole.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.errorDelete = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //GetAllRoles

    [getAllRoles.pending]: (state, action) => {
      state.loading = true;
      state.dataCreate = false;
      state.dataUpdate = false;
      state.singleData = false;
      state.error = false;
      state.dataSingle = false;
    },
    [getAllRoles.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = false;
      state.data = action.payload;
    },
    [getAllRoles.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getAllPermissions

    [getAllPermissions.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
    },
    [getAllPermissions.fulfilled]: (state, action) => {
      state.perme = action.payload;
      state.loading = false;
      state.error = false;
    },
    [getAllPermissions.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getSingleRole

    [getSingleRole.pending]: (state, action) => {
      state.loadingSingle = true;
      state.error = false;
    },
    [getSingleRole.fulfilled]: (state, action) => {
      state.dataSingle = action.payload;
      state.error = false;
      state.loadingSingle = false;
    },
    [getSingleRole.rejected]: (state, action) => {
      state.loadingSingle = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },
  },
});

export default roleSlice.reducer;
export let { resetsingleDataRole, resetDataCreate, resetDataUpdate } =
  roleSlice.actions;
