import "./login.scss";
import img1 from "../../assits/logo_app.svg";
import {
  Link,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockIcon from "@mui/icons-material/Lock";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { islogged, logdata } from "../../store/authslice";
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector } from "react-redux";
import { notifyError } from "../../Notification";
import { useTranslation } from "react-i18next";

function Login({ path }) {
  const [t, i18n] = useTranslation();
  let dispatch = useDispatch();
  let [recap, setReCap] = useState("");
  let [inp, setinp] = useState({
    email: "",
    password: "",
  });

  let getdata = (e) => {
    setinp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  let { data, loading, error } = useSelector(
    (state) => state.persistTheme.auth
  );
  let navigate = useNavigate();
  useEffect(() => {
    if (data) {
      navigate("/");
    }
  }, [data, navigate]);

  let location = useLocation();
  let getvalue = (e) => {
    e.preventDefault();
    if (!recap) {
      notifyError(
        "Please click on the verification option to make sure you are not a robot"
      );
    }
    if (inp.email && inp.password && recap) {
      if (location.state?.data) {
        dispatch(logdata(inp));
        // navigate(`/course/${location.state?.data}`);
      }
      dispatch(logdata(inp));
      if (data) {
        navigate(`${path ? path : "/"}`);
      }
    }
  };

  function onChange(value) {
    setReCap(value);
  }

  console.log("inpppp==>", inp);
  return (
    <div className="login">
      <form onSubmit={(e) => getvalue(e)}>
        <div className="box-login row">
          <div className="col-lg-12 inps">
            <div className="inp-icon">
              <MailOutlineIcon className="icon" />
              <input
                name="email"
                type="email"
                autoComplete="off"
                placeholder="Enter your Email.."
                required
                onChange={(e) => {
                  getdata(e);
                }}
              />
              <span>put your email please with @</span>
            </div>

            <div className="inp-icon">
              <LockIcon className="icon" />
              <input
                name="password"
                type="password"
                autoComplete="off"
                placeholder="Enter your password.."
                required
                onChange={(e) => {
                  getdata(e);
                }}
              />
            </div>

            <div className="text-center">
              <ReCAPTCHA
                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                security="6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe"
                onChange={onChange}
              />
            </div>

            {error && (
              <p style={{ color: "#970000", fontSize: "17px" }}>
                {error.data.message}
              </p>
            )}
            {loading ? (
              <button className="btn " disabled>
                {t("loading")}...
              </button>
            ) : (
              <button className="btn " disabled={!recap}>
                {t("Login")}
              </button>
            )}

            <hr />
            <div className="policy-ask text-center">
              <p>
                By signing up, you agree to our Terms & Condition , Privacy
                Policy.{" "}
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Login;
