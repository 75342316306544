import React, { Fragment, useState } from "react";
import "./ModalMulti.scss";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import FlagIcon from "@mui/icons-material/Flag";
import IosShareIcon from "@mui/icons-material/IosShare";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import PopUpUsers from "../../pages/Folder-Users/PopUpUsers/PopUpUsers";
import { Dialog } from "@mui/material";
import PopUpCompany from "../../pages/Folder-Comapny/PopUpComapny/PopUpCompany";
import PopUpCategory from "../../pages/Folder-Category/PopUpCategory/PopUpCategory";
import PopUpChronicD from "../../pages/FolderChronicD/PopUpChronicD/PopUpChronicD";
import PopUpAccessM from "../../pages/Folder-AccessMethod/PopUpAccessM/PopUpAccessM";
import PopUpLanguSpeak from "../../pages/Folder-LanguageSpeak/PopUpLanguSpeak/PopUpLanguSpeak";
import PopUpSubscription from "../../pages/Folder-Subscription/PopUpSubscription/PopUpSubscription";

function ModalMulti({ params: data, filter, type, title }) {
  const [t, i18n] = useTranslation();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const label = { inputProps: { "aria-label": "Size switch demo" } };
  return (
    <div>
      <div
        onClick={(e) => {
          handleOpen();
          e.stopPropagation();
        }}
        className={`d-flex gap-2 align-items-center ${title}`}
      >
        {type === "viwe" ? (
          <VisibilityIcon />
        ) : type === "edit" ? (
          <EditIcon />
        ) : type === "report" ? (
          <FlagIcon />
        ) : type === "share" ? (
          <IosShareIcon />
        ) : type === "addcity" ? (
          <AddLocationAltIcon />
        ) : type === "order-pop" ? (
          ""
        ) : type === "order-assign" ? (
          ""
        ) : (
          <AddIcon />
        )}
        {title && t(`${title}`)}
      </div>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
      >
        {filter === "users" ? (
          <PopUpUsers type={type} handleClose={handleClose} data={data} />
        ) : filter === "Companies" ? (
          <PopUpCompany type={type} handleClose={handleClose} data={data} />
        ) : filter === "Categories" ? (
          <PopUpCategory type={type} handleClose={handleClose} data={data} />
        ) : filter === "ChronicD" ? (
          <PopUpChronicD type={type} handleClose={handleClose} data={data} />
        ) : filter === "accessm" ? (
          <PopUpAccessM type={type} handleClose={handleClose} data={data} />
        ) : filter === "languspeak" ? (
          <PopUpLanguSpeak type={type} handleClose={handleClose} data={data} />
        ) : filter === "subscription" ? (
          <PopUpSubscription
            type={type}
            handleClose={handleClose}
            data={data}
          />
        ) : (
          ""
        )}
      </Dialog>
    </div>
  );
}

export default ModalMulti;
