import {
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  MenuItem,
  Select,
  InputAdornment,
  InputLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import {
  createAdmin,
  getAlladmins,
  getSingleAdmin,
  updateAdmin,
} from "../../../store/adminslice";
import { getadminsRoles } from "../../../store/commonSlice";

function PopUpUsers({ type, handleClose, data }) {
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  let [inp, setInp] = useState("");
  const [val, setVal] = useState("");

  const [showPassword, setShowPassword] = React.useState(false);
  const [t, i18n] = useTranslation();
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  let dispatch = useDispatch();

  let {
    loadingBTN,
    dataCreate,
    dataUpdate,
    loadingSingle,
    errorSingle,
    singleData,
  } = useSelector((state) => state.admins);

  let { roles, loadingroles, error } = useSelector(
    (state) => state.commonlists
  );

  useEffect(() => {
    if (dataCreate || dataUpdate) {
      dispatch(getAlladmins({ lang: i18n.language }));
      handleClose();
    }
  }, [dataCreate, dataUpdate]);

  useEffect(() => {
    if (type === "edit") {
      dispatch(getSingleAdmin({ id: data.id, lang: i18n.language }));
    }
    dispatch(getadminsRoles({ lang: i18n.language }));
  }, [type]);

  let getValue = (e) => {
    setVal(e.target.value);
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  let handleSwitchChange = (e, name) => {
    setInp((prev) => {
      return { ...prev, [name]: e.target.checked ? 1 : 0 };
    });
  };

  let onsubmitfn = (e) => {
    e.preventDefault();
    if (type === "add") {
      console.log("addd");
      dispatch(createAdmin({ data: inp, lang: i18n.language }));
    }
    if (type === "edit") {
      console.log("ediit");
      let newdata = {
        ...inp,
        _method: "PUT",
      };
      dispatch(
        updateAdmin({
          id: singleData.id,
          data: newdata,
          lang: i18n.language,
        })
      );
    }
  };

  console.log("inpp<...>", inp);
  console.log("singleData<...>", singleData);
  return (
    <Fragment>
      <DialogTitle sx={{ p: 3 }}>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            borderBottom: "1px solid lightgray",
            paddingBottom: "2px",
            marginBottom: "30px",
          }}
        >
          {type === "edit" ? "Edit Admin" : "Add Admin"}
        </Typography>

        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form class="row" onSubmit={(e) => onsubmitfn(e)}>
            <div className="col-md-6 mb-2">
              <div className="form-group">
                <TextField
                  id={"text"}
                  type={"text"}
                  label={t("name")}
                  name={"name"}
                  required
                  defaultValue={
                    type === "edit" ? singleData && singleData.name : ""
                  }
                  onChange={(e) => getValue(e)}
                  variant="standard"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="col-md-6  mb-2">
              <div className="form-group">
                <TextField
                  id={"email"}
                  required
                  type={"email"}
                  label={t("Email")}
                  name={"email"}
                  defaultValue={
                    type === "edit" ? singleData && singleData.email : ""
                  }
                  onChange={(e) => getValue(e)}
                  variant="standard"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="col-md-6  mb-2">
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <InputLabel htmlFor={"password"}>
                  {t("Password")} <sup className="redstar">*</sup>
                </InputLabel>
                <Input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  required={true}
                  onChange={(e) => getValue(e)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
            <div className="col-md-6  mb-2">
              <div className="form-group">
                <p>
                  {t("Status")}:<sup className="redstar">*</sup>
                </p>
                <Switch
                  {...label}
                  defaultChecked={
                    type === "edit" && singleData && singleData.active == 1
                      ? true
                      : false
                  }
                  onChange={(event) => handleSwitchChange(event, "active")}
                />
              </div>
            </div>

            <div className="col-md-12 mb-3">
              <FormControl fullWidth>
                <InputLabel id="role">{t("Role")}</InputLabel>
                <Select
                  labelId="Role"
                  name="role"
                  id="Role"
                  defaultValue={
                    type === "edit" && singleData ? singleData.role[0] : val
                  }
                  required
                  label={"Role"}
                  onChange={(e) => getValue(e)}
                >
                  {loadingroles ? (
                    <MenuItem disabled>loading</MenuItem>
                  ) : (
                    roles &&
                    roles.map((e) => (
                      <MenuItem key={e.id} value={e.id}>
                        {e.name}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </div>

            <div className="btn-lest mt-3">
              {loadingBTN ? (
                <button type="text" disabled className="btn btn-primary-rgba ">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" />
                  {type === "edit" ? t("Edit") : t("Craete")}
                </button>
              )}

              <span onClick={handleClose} className="btn btn-danger-rgba">
                <DoNotDisturbAltIcon color="#fff" /> {t("Cancel")}
              </span>
            </div>
          </form>
        )}
      </DialogTitle>
    </Fragment>
  );
}

export default PopUpUsers;
