import React from "react";
import "./Actions.scss";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalMulti from "../ModalMulti/ModalMulti";
import ModalDelete from "../ModalDelete/ModalDelete";
import { useNavigate } from "react-router-dom";

function Actions({ filter, params }) {
  let navigate = useNavigate();
  let handlepath = (path) => {
    navigate(path);
  };
  return (
    <div className="action-icon">
      {filter === "users" ? (
        <div className="list-icons">
          {/*view */}
          {/* <div className="icon-view">
            <VisibilityIcon />
          </div> */}
          {/*edit */}
          <div className="icon-edit">
            <ModalMulti params={params} filter="users" type="edit" />
          </div>
          {/*delte */}
          <div className="icon-delete">
            <ModalDelete filter="users" params={params} />
          </div>
        </div>
      ) : filter === "Companies" ? (
        <div className="list-icons">
          {/*view */}

          {/* <div className="icon-view">
          <VisibilityIcon />
        </div> */}

          {/*edit */}
          <div className="icon-edit">
            <ModalMulti params={params} filter="Companies" type="edit" />
          </div>

          {/*delte */}
          <div className="icon-delete">
            <ModalDelete filter="Companies" params={params} />
          </div>
        </div>
      ) : filter === "Categories" ? (
        <div className="list-icons">
          {/*view */}

          {/* <div className="icon-view">
        <VisibilityIcon />
      </div> */}

          {/*edit */}
          <div className="icon-edit">
            <ModalMulti params={params} filter="Categories" type="edit" />
          </div>

          {/*delte */}
          <div className="icon-delete">
            <ModalDelete filter="Categories" params={params} />
          </div>
        </div>
      ) : filter === "ChronicD" ? (
        <div className="list-icons">
          {/*view */}

          {/* <div className="icon-view">
      <VisibilityIcon />
    </div> */}

          {/*edit */}
          <div className="icon-edit">
            <ModalMulti params={params} filter="ChronicD" type="edit" />
          </div>

          {/*delte */}
          <div className="icon-delete">
            <ModalDelete filter="ChronicD" params={params} />
          </div>
        </div>
      ) : filter === "accessm" ? (
        <div className="list-icons">
          {/*view */}

          {/* <div className="icon-view">
    <VisibilityIcon />
          </div> */}

          {/*edit */}
          <div className="icon-edit">
            <ModalMulti params={params} filter="accessm" type="edit" />
          </div>

          {/*delte */}
          <div className="icon-delete">
            <ModalDelete filter="accessm" params={params} />
          </div>
        </div>
      ) : filter === "languspeak" ? (
        <div className="list-icons">
          {/*view */}

          {/* <div className="icon-view">
  <VisibilityIcon />
        </div> */}

          {/*edit */}
          <div className="icon-edit">
            <ModalMulti params={params} filter="languspeak" type="edit" />
          </div>

          {/*delte */}
          <div className="icon-delete">
            <ModalDelete filter="languspeak" params={params} />
          </div>
        </div>
      ) : filter === "subscription" ? (
        <div className="list-icons">
          {/*view */}

          <div className="icon-view">
            <VisibilityIcon />
          </div>

          {/*edit */}
          <div className="icon-edit">
            <ModalMulti params={params} filter="subscription" type="edit" />
          </div>

          {/*delte */}
          <div className="icon-delete">
            <ModalDelete filter="subscription" params={params} />
          </div>
        </div>
      ) : (
        filter === "Role" ? 
        <div className="list-icons">
        {/*view */}

        {/*edit */}
        <div className="icon-edit">
          <EditIcon
            onClick={(e) =>
              handlepath(`/roles-permision/edit/${params.row.id}`)
            }
          />
        </div>
        {/*delte */}
        <div className="icon-delete">
          <ModalDelete filter="Role" params={params} />
        </div>
       </div>
        :""
      )}
    </div>
  );
}

export default Actions;
