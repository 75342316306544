import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { notifyError, notifysuccess } from "../Notification";
import { BASE_URL } from "../apiConfig";

export let createCompany = createAsyncThunk(
  "company/createCompany",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token; // Access the token from the Redux store
    try {
      let res = await axios.post(
        `${BASE_URL}/admin-panal/companies`,
        arg.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
            lang: arg.lang,
          },
        }
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getAllCompany = createAsyncThunk(
  "company/getAllCompany",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const data = await axios.get(`${BASE_URL}/admin-panal/companies`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      });

      console.log("data,", data.data.result.companies.data);
      return data.data.result.companies.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const deleteComapny = createAsyncThunk(
  "company/deleteComapny",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const idList = Array.isArray(arg.id) ? arg.id : [arg.id]; // Ensure id is an array
      const promises = idList.map((id) =>
        axios.post(`${BASE_URL}/admin-panal/companies/${id}`, arg.data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        })
      );

      // Wait for all deletion requests to complete
      const responses = await Promise.all(promises); // Wait for all deletion requests to complete

      return { idList: idList, responses: responses }; // Return the list of deleted IDs
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let getSingleCompany = createAsyncThunk(
  "company/getSingleCompany",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;

    try {
      let data = await axios.get(
        `${BASE_URL}/admin-panal/companies/${arg.id}`,
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
            lang: arg.lang,
          },
        }
      );
      console.log("data.data.result.compa", data.data.result.company);
      return data.data.result.company;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let updateCompany = createAsyncThunk(
  "company/updateCompany",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;

    try {
      let data = await axios.post(
        `${BASE_URL}/admin-panal/companies/${arg.id}`,
        arg.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
            lang: arg.lang,
          },
        }
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

let comapnyslice = createSlice({
  name: "comapny",
  initialState: {
    data: false,
    dataUpdate: false,
    dataCreate: false,
    error: false,
    loading: false,
    loadingSingle: false,
    singleData: false,
    loadingBTN: false,
    errorDelete: false,
    errorSingle: false,
    errorAll: false,
  },
  reducers: {
    resetAllSellerAction: (state, action) => {
      state.data = action.payload;
    },
    resetsingleDataComapny: (state, action) => {
      state.singleData = action.payload;
    },
    resetDataCreate: (state, action) => {
      state.dataCreate = action.payload;
    },
    resetDataUpdate: (state, action) => {
      state.dataUpdate = action.payload;
    },
  },
  extraReducers: {
    //createCompany

    [createCompany.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [createCompany.fulfilled]: (state, action) => {
      state.error = false;
      state.dataCreate = action.payload;
      notifysuccess(action.payload.message);
      state.loadingBTN = false;
    },
    [createCompany.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //updateCompany

    [updateCompany.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [updateCompany.fulfilled]: (state, action) => {
      state.loadingBTN = false;
      state.error = false;
      state.dataUpdate = action.payload;
      notifysuccess(action.payload.message);
      // state.data = action.payload;
    },
    [updateCompany.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getSingleCompany

    [getSingleCompany.pending]: (state, action) => {
      state.loadingSingle = true;
      state.errorSingle = false;
    },
    [getSingleCompany.fulfilled]: (state, action) => {
      state.singleData = action.payload;
      state.errorSingle = false;
      state.loadingSingle = false;
    },
    [getSingleCompany.rejected]: (state, action) => {
      state.loadingSingle = false;
      state.errorSingle = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //deleteComapny

    [deleteComapny.pending]: (state, action) => {
      state.loadingBTN = true;
      state.errorDelete = false;
    },
    [deleteComapny.fulfilled]: (state, action) => {
      state.data = state.data.filter(
        (e) => !action.payload.idList.includes(e.id)
      );
      state.loadingBTN = false;
      state.errorDelete = false;
      notifysuccess(action.payload.responses[0].data.message);
    },
    [deleteComapny.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.errorDelete = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getAllCompany

    [getAllCompany.pending]: (state, action) => {
      state.loading = true;
      state.dataCreate = false;
      state.dataUpdate = false;
      state.singleData = false;
      state.errorAll = false;
      state.data = false;
    },
    [getAllCompany.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.errorAll = false;
      state.loading = false;
    },
    [getAllCompany.rejected]: (state, action) => {
      state.loading = false;
      state.errorAll = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },
  },
});

export default comapnyslice.reducer;
export let {
  resetAllSellerAction,
  resetsingleDataComapny,
  resetDataCreate,
  resetDataUpdate,
} = comapnyslice.actions;
