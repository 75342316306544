import { Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Actions from "../../../components/Actions/Actions";
import { useDispatch } from "react-redux";
import Navbar from "../../../components/Navbar/Navbar";
import HeaderPage from "../../../components/HeaderPage/HeaderPage";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import Datatable from "../../../components/datatable/Datatable";
import { useSelector } from "react-redux";
import { getAllSubsec } from "../../../store/subscriptionSlice";
import { ChangeStatus } from "../../../store/adminslice";

function MainSubscription() {
  const [t, i18n] = useTranslation();
  const label = { inputProps: { "aria-label": "Size switch demo" } };

  const userColumns = [
    { field: "id", headerName: t("ID"), width: 100 },
    {
      field: "name",
      headerName: t("Name"),
      width: 150,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">{`${params.row.company.name}`}</div>
        );
      },
    },

    {
      field: "start_date",
      headerName: t("Start Date"),
      width: 150,
    },

    {
      field: "end_date",
      headerName: t("End Date"),
      width: 150,
    },

    {
      field: "cost",
      headerName: t("Cost"),
      width: 150,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">{`${params.row.user_subscription_transactions[0].cost}`}</div>
        );
      },
    },

    {
      field: "status",
      headerName: t("Status"),
      width: 130,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">{`${params.row.user_subscription_transactions[0].status}`}</div>
        );
      },
    },

    {
      field: "active",
      headerName: t("Active"),
      width: 130,
      renderCell: (params) => {
        return (
          <div className="status">
            <Switch
              {...label}
              onChange={(event) => getStatus(event, params.row.id)}
              defaultChecked={params.row.active == "1" ? true : false}
            />
          </div>
        );
      },
    },

    {
      field: "Action",
      headerName: t("Action"),
      sortable: false,
      filter: false,
      width: 150,
      renderCell: (params) => {
        return (
          <div>
            <Actions filter="subscription" params={params} />
          </div>
        );
      },
    },
  ];

  // let getStatus = (e, id) => {
  //   let data = {
  //     model_id: id,
  //     model_class: "Driver",
  //     attribute: "status",
  //     value: e.target.checked ? 1 : 0,
  //   };
  //   dispatch(ChangeStatus(data));
  // };

  let getStatus = (e, id) => {
    let data = {
      name: "subscriptions",
      id: id,
      active: e.target.checked ? 1 : 0,
    };
    dispatch(ChangeStatus(data));
  };

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  // Function to handle selection change
  const handleSelectionChange = (selectionModel) => {
    // Store the selected row IDs in state
    setSelectedRowIds(selectionModel);
    // Log the selected row IDs to the console
    console.log("Selected Row IDs:", selectionModel);
  };

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllSubsec({ lang: i18n.language }));
  }, []);

  let {
    data: subscription,
    loading,
    errorAll,
  } = useSelector((state) => state.subscription);

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <HeaderPage
          data={subscription}
          selectedRowIds={selectedRowIds}
          title="Subscription"
          filter="subscription"
        />
        <div className="table">
          {errorAll ? (
            <ErrorCompo />
          ) : loading ? (
            <div className="loading">
              <CircularIndeterminate />
            </div>
          ) : (
            <Datatable
              userColumns={userColumns}
              userRows={subscription && subscription}
              onSelectionModelChange={handleSelectionChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MainSubscription;
