import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { notifyError, notifysuccess } from "../Notification";
import { BASE_URL } from "../apiConfig";

export const getadminsRoles = createAsyncThunk(
  "common/getadminsRoles",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const data = await axios.get(`${BASE_URL}/core/common/list/admin-roles`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      });
      return data.data.result.list;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getacompaniesList = createAsyncThunk(
  "common/getacompaniesList",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const data = await axios.get(`${BASE_URL}/core/common/list/companies`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      });
      return data.data.result.list;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getasubscriptionList = createAsyncThunk(
  "common/getasubscriptionList",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const data = await axios.get(
        `${BASE_URL}/core/common/list/subscription-status`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        }
      );
      return data.data.result.list;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
export const getSupsecrptionMethods = createAsyncThunk(
  "common/getSupsecrptionMethods",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const data = await axios.get(
        `${BASE_URL}/core/common/list/subscription-payment-method`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        }
      );
      console.log("data.data.resul", data.data);
      return data.data.result.list;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

let commonslice = createSlice({
  name: "commonslice",
  initialState: {
    loadingroles: false,
    loadingcompanies: false,
    loadingSub: false,
    subscriptionMEthod: false,
    loaidngMethod: false,
    error: false,
    roles: false,
    subscription: false,
    companies: false,
  },
  reducers: {},
  extraReducers: {
    //getadminsRoles

    [getadminsRoles.pending]: (state, action) => {
      state.loadingroles = true;
      state.error = false;
      state.roles = false;
    },
    [getadminsRoles.fulfilled]: (state, action) => {
      state.roles = action.payload;
      state.error = false;
      state.loadingroles = false;
    },
    [getadminsRoles.rejected]: (state, action) => {
      state.loadingroles = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getacompaniesList

    [getacompaniesList.pending]: (state, action) => {
      state.loadingcompanies = true;
      state.error = false;
    },
    [getacompaniesList.fulfilled]: (state, action) => {
      state.companies = action.payload;
      state.error = false;
      state.loadingcompanies = false;
    },
    [getacompaniesList.rejected]: (state, action) => {
      state.loadingcompanies = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getasubscriptionList

    [getasubscriptionList.pending]: (state, action) => {
      state.loadingSub = true;
      state.error = false;
    },
    [getasubscriptionList.fulfilled]: (state, action) => {
      state.subscription = action.payload;
      state.error = false;
      state.loadingSub = false;
    },
    [getasubscriptionList.rejected]: (state, action) => {
      state.loadingSub = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getSupsecrptionMethods

    [getSupsecrptionMethods.pending]: (state, action) => {
      state.loaidngMethod = true;
      state.error = false;
    },
    [getSupsecrptionMethods.fulfilled]: (state, action) => {
      console.log("action.payloadaction.payload", action.payload);
      state.subscriptionMEthod = action.payload;
      state.error = false;
      state.loaidngMethod = false;
    },
    [getSupsecrptionMethods.rejected]: (state, action) => {
      state.loaidngMethod = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },
  },
});

export default commonslice.reducer;
