import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import HeaderPage from "../../../components/HeaderPage/HeaderPage";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import Datatable from "../../../components/datatable/Datatable";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../../../components/Actions/Actions";
import { useTranslation } from "react-i18next";
import { Switch } from "@mui/material";
import {
  getAllCompany,
  resetsingleDataComapny,
} from "../../../store/companySlice";
import { ChangeStatus } from "../../../store/adminslice";

function MainCompany() {
  const [t, i18n] = useTranslation();
  const label = { inputProps: { "aria-label": "Size switch demo" } };

  const userColumns = [
    { field: "id", headerName: t("ID"), width: 80 },
    {
      field: "name",
      headerName: t("Name"),
      width: 250,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            {params.row.img ? (
              <img className="cellImg" src={params.row.img} alt="avatar" />
            ) : (
              <div className="boxImageChar">
                <span>{params.row.name.slice(0, 1)}</span>
              </div>
            )}
            {`${params.row.name}`}
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: t("Email"),
      width: 200,
    },
    {
      field: "tax_no",
      headerName: t("Tax NO"),
      width: 130,
    },
    {
      field: "commercial_registration_no",
      headerName: t("Commercia NO"),
      width: 130,
    },
    {
      field: "auto_renew",
      headerName: t("Auto Renew"),
      width: 110,
      renderCell: (params) => {
        return (
          <div className="status">
            <Switch
              {...label}
              defaultChecked={params.row.auto_renew == 1 ? true : false}
            />
          </div>
        );
      },
    },
    {
      field: "active",
      headerName: t("Active"),
      width: 130,
      renderCell: (params) => {
        return (
          <div className="status">
            <Switch
              {...label}
              onChange={(event) => getStatus(event, params.row.id)}
              defaultChecked={params.row.active == 1 ? true : false}
            />
          </div>
        );
      },
    },

    {
      field: "Action",
      headerName: t("Action"),
      sortable: false,
      filter: false,
      width: 100,
      renderCell: (params) => {
        return (
          <div>
            <Actions filter="Companies" params={params} />
          </div>
        );
      },
    },
  ];

  let getStatus = (e, id) => {
    let data = {
      name: "companies",
      id: id,
      active: e.target.checked ? 1 : 0,
    };
    dispatch(ChangeStatus(data));
  };

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  // Function to handle selection change
  const handleSelectionChange = (selectionModel) => {
    // Store the selected row IDs in state
    setSelectedRowIds(selectionModel);
    // Log the selected row IDs to the console
    console.log("Selected Row IDs:", selectionModel);
  };

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCompany({ lang: i18n.language }));
    dispatch(resetsingleDataComapny(""));
  }, []);

  let {
    data: companies,
    loading,
    errorAll,
  } = useSelector((state) => state.companies);

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <HeaderPage
          data={companies}
          selectedRowIds={selectedRowIds}
          title="Companies"
          filter="Companies"
        />
        <div className="table">
          {errorAll ? (
            <ErrorCompo />
          ) : loading ? (
            <div className="loading">
              <CircularIndeterminate />
            </div>
          ) : (
            <Datatable
              userColumns={userColumns}
              userRows={companies && companies}
              onSelectionModelChange={handleSelectionChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MainCompany;
