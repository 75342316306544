import img1 from "../../assits/logo-w.png";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import LogoutIcon from "@mui/icons-material/Logout";
import CategoryIcon from "@mui/icons-material/Category";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import TranslateIcon from "@mui/icons-material/Translate";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AddCardIcon from "@mui/icons-material/AddCard";
import "./sidebar.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Logout } from "../../store/authslice";

function Sidebar() {
  const [t, i18n] = useTranslation();
  let { showslider } = useSelector((state) => state.persistTheme.auth);
  let [showMenu, setShowMenu] = useState({});
  let [toggle, setToggle] = useState(true);
  let toggleMenu = (n) => {
    setToggle(!toggle);
    setShowMenu({ name: n, state: toggle });
  };
  let dispatch = useDispatch();

  let navigate = useNavigate();
  return (
    <div className={!showslider ? "big" : "big2"}>
      <div className="list img-logo">
        <img src={img1} alt="..." />
      </div>
      <div className="list">
        <ul>
          <NavLink to="/">
            <DashboardIcon className="icon-s" />
            <span> {t("Dashboard")}</span>
          </NavLink>
        </ul>
      </div>

      <div className="list">
        <ul className="list-unstyled">
          <NavLink to="/admins">
            <PeopleAltIcon className="icon-s" />
            {t("Admins")}
          </NavLink>

          <NavLink to="/roles-permision">
            <ManageAccountsIcon className="icon-s" />
            {t("Roles And Permission")}
          </NavLink>

          <NavLink to="/companies">
            <AccountBalanceIcon className="icon-s" />
            {t("Companies")}
          </NavLink>

          <NavLink to="/subscription">
            <AddCardIcon className="icon-s" />
            {t("Subscription")}
          </NavLink>

          <NavLink to="/categories">
            <CategoryIcon className="icon-s" />
            {t("Categories")}
          </NavLink>

          <NavLink to="/chronic-diseases">
            <VaccinesIcon className="icon-s" />
            {t("Chronic Diseases")}
          </NavLink>
          <NavLink to="/access-methods">
            <TravelExploreIcon className="icon-s" />
            {t("Access Methods")}
          </NavLink>
          <NavLink to="/language-speak">
            <TranslateIcon className="icon-s" />
            {t("Language Speak")}
          </NavLink>
        </ul>
      </div>

      <div className="list">
        <ul className="list-unstyled">
          <NavLink to="/settings">
            <SettingsSuggestIcon className="icon-s" />
            {t("Privacy Policy")}
          </NavLink>
        </ul>
      </div>

      <div className="list">
        <ul className="list-unstyled">
          <div
            onClick={() => {
              dispatch(Logout());
              navigate("/login");
            }}
          >
            <LogoutIcon className="icon-s" />
            {t("Logout")}
          </div>
        </ul>
      </div>

      {/* <div className="list">
        <h6>Theme</h6>
       *<div className="themesfather">
          <div className="theme white"></div>
          <div className="theme dark"></div>
        </div>*
        <div className="themesfather">
          <div className="theme white"></div>
          <div className="theme dark"></div>
        </div>
      </div>*/}
    </div>
  );
}

export default Sidebar;
